/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    string, object,
} from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { determineChatlink } from '../Global_chat_helpers';

const triggerPopChatEvent = (payload = {}) => {
    if (typeof window !== 'undefined') {
        const popOpenDesktopChat = new CustomEvent('popOpenChat', { detail: payload });
        window.dispatchEvent(popOpenDesktopChat);
    }
};

export const RenderChatAsLink = ({
    value, operatingSystem, currentBrand, featureFlags,
}) => {
    const chatRegex = /(chatting|chat)/gi;
    const parts = value?.split(chatRegex);
    const renderPart = (part, index) => {
        const linkStyle = operatingSystem !== 'android' ? { textDecoration: 'underline', cursor: 'pointer' } : null;
        if (part?.toLowerCase() === 'chat' || part?.toLowerCase() === 'chatting') {
            return (
                <a
                    key={index}
                    onClick={() => {
                        if (operatingSystem !== 'android' && operatingSystem !== 'ios') {
                            triggerPopChatEvent({});
                        }
                    }}
                    onKeyDown={() => { }}
                    href={determineChatlink(operatingSystem, currentBrand, featureFlags) || null}
                    aria-hidden="true"
                    tabIndex="0"
                    alt="chat"
                    style={linkStyle}
                >
                    {part}
                </a>
            );
        }
        // removing extra br tag
        const cleanedPart = part?.replace(/<\/br>/g, '');
        return <React.Fragment key={index}>{ReactHtmlParser(cleanedPart)}</React.Fragment>;
    };
    return (
        <span>
            {parts?.map((part) => (
                <>
                    {renderPart(part)}
                </>
            ))}
        </span>
    );
};

RenderChatAsLink.propTypes = {
    value: string.isRequired,
    operatingSystem: string.isRequired,
    currentBrand: string.isRequired,
    featureFlags: object.isRequired,
};

export default triggerPopChatEvent;
