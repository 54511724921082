/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './AddressSearchQAS-Actions';
import initialState from '../../initialState';

const qasAddressSearchInitalState = initialState.addressSearchQAS;

const qasAddressSearch = (state = qasAddressSearchInitalState, action) => {
    switch (action.type) {
        case actions.loadQasAddressSearch().type:
            return {
                ...state,
                suggestion: action.data,
            };
        case actions.clearQasAddressSearch().type:
            return {
                ...state,
                suggestion: {},
            };
        case actions.loadFomattedQasAddress().type:
            return {
                ...state,
                formattedAddress: action.data,
            };
        case actions.clearFomattedQasAddress().type:
            return {
                ...state,
                formattedAddress: {},
            };
        case actions.flagOnShowValidatedAddressSuggestion().type:
            return {
                ...state,
                showValidatedAddressSuggestion: true,
            };
        case actions.flagOffShowValidatedAddressSuggestion().type:
            return {
                ...state,
                showValidatedAddressSuggestion: false,
            };
        case actions.resetQasAddressState().type:
            return {
                ...state,
                suggestion: {},
                formattedAddress: {},
                showValidatedAddressSuggestion: false,
            };
        case actions.flagOnQasFailed().type:
            return {
                ...state,
                qasFailed: true,
            };
        default:
            return state;
    }
};

export default qasAddressSearch;
