/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const ABCChatLinksByBrand = {
    '18F': 'https://bcrw.apple.com/urn:biz:a80a7352-e042-11e7-af38-7b88b04daa8e',
    '18B': 'https://bcrw.apple.com/urn:biz:df8107e5-b9a6-4e68-b329-e3e5b1247e21',
    HD: 'https://bcrw.apple.com/urn:biz:9ab55a12-ccb6-458d-8c6a-758cbf5ec242',
    CCO: 'https://bcrw.apple.com/urn:biz:f45ba478-770d-4a0a-b07f-0150d513a83b',
    TPF: 'https://bcrw.apple.com/urn:biz:2f592990-00e3-4d90-95d4-e6166978019e',
    SCH: 'https://bcrw.apple.com/urn:biz:f2e39503-9a29-4785-addb-bb89f0733e13',
    SY: 'https://bcrw.apple.com/urn:biz:54faf34c-2648-409d-ba11-a4626037a085',
    WLF: 'https://bcrw.apple.com/urn:biz:0a995446-c8f7-4206-83e5-da936feed683',
    FB: 'https://bcrw.apple.com/urn:biz:4a741121-9782-4688-8d64-e6c485394b26',
    FBQ: 'https://bcrw.apple.com/urn:biz:4a741121-9782-4688-8d64-e6c485394b26',
    BRY: 'https://bcrw.apple.com/urn:biz:5008980e-55ab-4892-8de5-1b2de545bcfe',
    VC: 'https://bcrw.apple.com/urn:biz:a8b8309f-5b4e-47b6-a2f8-b5ba8fb33c6c',
};

export const determineChatlink = (operatingSystem, currentBrand, featureFlags) => {
    const isMobileChatEnabled = ((featureFlags['is-gbm-in-pwa-enabled'] && operatingSystem === 'android') || (featureFlags['is-abc-in-pwa-enabled'] && operatingSystem === 'ios'));
    let chatLink = '';
    if (operatingSystem === 'android' && isMobileChatEnabled) {
        if (featureFlags['is-gbm-floral-brands-enabled'] && ['18B', 'SCH', 'BRY', 'FB', 'FBQ'].includes(currentBrand)) {
            chatLink = '';
        } else {
            chatLink = '';
        }
    }
    if (operatingSystem === 'ios' && isMobileChatEnabled) {
        chatLink = ABCChatLinksByBrand[currentBrand];
    }
    return chatLink;
};
