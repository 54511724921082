/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';

const findPersonalizedContentSF = (queryName = 'personalizedContent') => (
    gql`query ${queryName} (
        $brand: String!
        $environment: String!
        $user: UserSF!
        $targeting: TargetingSF!
        $userAttributes: [UserAttributesSF]
    ) {
        contentSF(
            brand: $brand,
            environment: $environment,
            user: $user,
            targeting: $targeting,
            userAttributes: $userAttributes,
        ) {
            campaign
            content
        }
    }`
);

export default findPersonalizedContentSF;
