/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './Passport-Action';

const INITIAL_STATE = {
    error: '',
    errorList: [],
    loader: false,
    passportProcess: 0,
    showPassportConsentToRmGC: false,
    tncContent: '',
    dynamicPassportCharge: '',
    passportData: {},
};

const passportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.clearPassportError().type:
            return {
                ...state,
                error: '',
                errorList: {},
            };
        case actions.loadPassportError().type:
            return {
                ...state,
                error: action.data.error,
                errorList: action.data.errorList,
            };
        case actions.loadPassportLoader().type:
            return { ...state, loader: action.data.loader };
        case actions.setPassportData().type:
            return {
                ...state,
                passportData: action.data.passportData,
            };
        case actions.loadPassportTncContent().type:
            return {
                ...state,
                tncContent: action.data.tncContent,
            };
        case actions.showPassportModalToRemoveGC().type:
            return {
                ...state,
                showPassportConsentToRmGC: true,
            };
        case actions.hidePassportModalToRemoveGC().type:
            return {
                ...state,
                showPassportConsentToRmGC: false,
            };
        case actions.passportProcessStart().type:
            return {
                ...state,
                passportProcess: 1,
            };
        case actions.passportProcessEnd().type:
            return {
                ...state,
                passportProcess: 0,
            };
        case actions.setPassportBundleData().type:
            return {
                ...state,
                passportBundle: action.data.passportBundle,
            };
        case actions.setPassportBundleCollectionData().type:
            return {
                ...state,
                passportBundleCollection: action.data.passportBundleCollection,
            };
        case actions.setPassportCampaignData().type:
            return {
                ...state,
                passportCampaign: action.data.passportCampaign,
            };
        case actions.setPassportSignInData().type:
            return {
                ...state,
                passportSignIn: action.data.passportSignInData,
            };
        case actions.openPassportSideBar().type:
            return {
                ...state,
                passportSideBarStatus: action.data,
            };
        case actions.closePassportSideBar().type:
            return {
                ...state,
                passportSideBarStatus: action.data,
            };
        case actions.setPassportABData().type:
            return {
                ...state,
                passportABData: action.data,
            };
        default:
            return state;
    }
};

export default passportReducer;
