/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, array, bool,
} from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Slider from 'react-slick';
import { useUIDSeed } from 'react-uid';
import LockIcon from '@material-ui/icons/Lock';
import NavigationPaginate from '../../../../ContentTypeComponents/Buttons/NavigationPaginate';

const styles = (theme) => ({
    mainSliderContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        paddingTop: '15px',
        [theme.breakpoints.down(599)]: {
            paddingTop: '0px !important',
        },
        marginBottom: '50px',
    },
    images: {
        maxWidth: '500px !important',
        border: 'none',
        outline: '0 !important',
        position: 'relative',
        width: '-webkit-fill-available',
    },
    lockedOverlay: {
        height: '100%',
        width: '100%',
        opacity: '.3',
        display: 'relative',
        cursor: 'not-allowed',
    },
    sliderContainer: {
        width: '100%',
        '& a': {
            display: 'inherit',
            fontSize: '16px',
            fontWeight: '400',
            textDecoration: 'none !important',
            color: theme.palette.cms?.black || ' #000',
            outline: '0 !important',
        },
        position: 'relative',
        '& svg': {
            position: 'absolute',
            color: '#797979',
            // color: '#c8c8c8',
            top: '45%',
            left: '46%',
            zIndex: 1000,
        },
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 auto',
            width: '100%',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: '0 8px',
            verticalAlign: 'top',
            '& div': {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        '& .backwardArrow, & .forwardArrow': {
            cursor: 'pointer',
            bottom: '-56px',
            width: 40,
            height: 40,
            position: 'absolute',
            alignItems: 'center !important',
            top: '50px',
        },
        '& .backwardArrow': {
            top: '43%',
            left: '-46px',
        },
        '& .forwardArrow': {
            top: '43%',
            right: '-50px',
        },
        '& .slick-disabled': {
            '& .back': {
                filter: 'invert(70%)',
            },
            '& .forward': {
                filter: 'invert(70%)',
            },
            cursor: 'not-allowed',
            borderColor: `${theme.palette.cms?.horizentalLineBorder || '#DEDEDE'} !important`,
            backgroundColor: '#00000008 !important',
            color: theme.palette.cms?.diabledBorderColor || '#DCDCDC',
        },
    },
});

const MobileSlider = ({
    classes, imagesKey, imageObj, isActivePassportUser,
}) => {
    const seed = useUIDSeed();
    const renderImages = (hasLink, imageDetail) => {
        if (hasLink && isActivePassportUser) {
            return (
                <a href={imageDetail?.link?.href}>
                    <img src={imageDetail?.image?.url} alt={imageDetail?.image?.title}  className={`${classes.images} ${!isActivePassportUser && classes.lockedOverlay}`} />
                </a>
            );
        }
        return <img src={imageDetail?.image?.url} alt={imageDetail?.image?.title}  className={`${classes.images} ${!isActivePassportUser && classes.lockedOverlay}`} />;
    };
    const getSlideCount = (obj) => {
        let count = Object.values(obj)?.filter((x) => typeof x === 'object' && x.image)?.length || 1;
        if (count > 3) count = 3;
        return count;
    };
    return (
        <Slider
            infinite
            speed={500}
            slidesToShow={getSlideCount(imageObj)}
            slidesToScroll={getSlideCount(imageObj) - 1}
            nextArrow={<NavigationPaginate color="#333333" className={classes} direction="forward" />}
            prevArrow={<NavigationPaginate color="#333333" className={classes} direction="back" />}
            className={`carousel-container ${classes.sliderContainer}`}
            responsive={[
                {
                    breakpoint: 830, // tablet breakpoint
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 560, // mobile breakpoint
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ]}
        >
            {imagesKey?.map((key) => {
                const imageDetail = imageObj[key];
                if (['enable_carousel', 'carousel_title'].includes(key)) {
                    return null;
                }
                if (!imageDetail?.image || typeof imageDetail?.image === 'string') { // If empty CMS fields, return null so empty space does not appear on page
                    return null;
                }
                const hasLink = imageDetail?.link && imageDetail.link?.href;

                return (

                    <div
                        role="presentation"
                        key={seed(key)}
                        className={`${classes.images} `}
                    >
                        {!isActivePassportUser && <LockIcon className={classes.lockedIcon} />}
                        {renderImages(hasLink, imageDetail)}
                    </div>

                );
            })}
        </Slider>
    );
};

MobileSlider.propTypes = {
    classes: object.isRequired,
    imageObj: object,
    imagesKey: array,
    isActivePassportUser: bool,
};
MobileSlider.defaultProps = {
    imageObj: {},
    imagesKey: [],
    isActivePassportUser: false,
};
export default  withStyles(styles)(MobileSlider);
