/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import useBrowserUUID from '../useBrowserUUID';

/* eslint-disable no-restricted-syntax, no-loop-func */

// single and multiple product payload:
// const payload = {
//    enterpriseId,
//    products: [
//        {
//            partNumber,
//        },
//    ],
// };

class Debouncer {
    constructor(client) {
        this.client = client;
        this.requests = new Map();

        this.intervalId = null;
        this.timeMS = 250;

        this.env = null;
        this.jwtToken = null;
        this.resourcePage = null;
        this.enterpriseId = null;
    }

    /**
     *
     * @param {*} response
     */
    triggerImpressionEvents = (response) => {
        if (typeof document !== 'undefined' && typeof window !== 'undefined') {
            if (response?.data?.variants?.length > 0) {
                (response?.data?.variants || []).forEach((item) => {
                    let toJson = {};
                    try {
                        toJson = JSON.parse(item?.value);
                    } catch (e) {
                        this.handleGroupedFetchFailure('Trigger tracking:', e);
                    }
                    (toJson?.campaign?.campaignResponses || []).forEach((campaign) => {
                        const isControl = campaign?.userGroup?.toLowerCase?.() === 'control';
                        document.dispatchEvent(new CustomEvent('triggerTagManagerEvent', {
                            detail: {
                                eventCategory: 'Experiment',
                                eventAction: campaign?.campaignName || '',
                                eventLabel: isControl ? 'control' : campaign?.experienceName || '',
                                experimentPage: window?.pageType || '',
                                experienceId: campaign?.experienceId || '',
                                campaignId: campaign?.campaignId || '',
                                variant: campaign?.userGroup || '',
                            },
                        },
                        ));
                    });
                });
            }
        }
    }

    /**
    * Generate an id composite of all the partNumbers in a single "request"
    * for easy lookup when mapping data back.
    * @param {array} products [{ partNumber }]
    * @returns {string} id
    */
   generateTupleId = (products = []) => products.reduce((id, product) => {
       if (!id.length) {
           return product.partNumber;
       }
       return `${id},${product.partNumber}`;
   }, '')

   /**
    * Set the request in the map to be called along with others at a later time.
    * @param {object} wcEnv
    * @param {string} jwtToken
    * @param {string} resourcePage
    * @param {object} payload {enterpriseId: string, products: object}
    * @param {function} finish args: err, data
    * @returns
    */
  addRequest = (wcEnv, jwtToken, resourcePage, payload, finish) => {
      if (!payload?.products?.length) {
          finish('No products in the payload');
          return;
      }

      // set variables that wont change per request
      if (!this.env) {
          this.env = wcEnv;
          this.jwtToken = jwtToken;
          this.resourcePage = resourcePage;
          this.enterpriseId = payload.enterpriseId;
      }

      // create an id from all the partnumbers, part numbers should be fine
      // since the price between pages should always be the same per part number
      const mapId = this.generateTupleId(payload.products);

      // have we already generated a reuqest for this id?
      const request = this.requests.get(mapId);
      if (request) {
          // in this case, lets push the extra finish function
          request.finish.push(finish);
          this.requests.set(mapId, request);
      } else {
          // no request found for this id
          // setting the reqest, add a bunch of useful refs
          this.requests.set(mapId, {
              params: {
                  wcEnv,
                  jwtToken,
                  payload,
              },

              // called to exit the grouping flow with data or an error
              finish: [finish],

              // nPartNumbers is the expected number of products from the resulting call
              nPartNumbers: payload.products.length,
              // match this with nPartNumbers so we know a request is completely fulfilled
              nResponses: 0,

              // used to prevent this specific request being used
              // in multiple groupFetches
              inProgress: false,

              // pass this to finish at the end
              data: {
                  enterpriseId: this.enterpriseId,
                  products: [],
                  variants: [],
              },
          });
      }

      // if we are already funning the interval, dont bother
      if (!this.intervalId) {
          this.intervalId = setInterval(() => {
              this.groupFetch();
          }, this.timeMS);
      }
  }

  /**
   * Loops over the pending requests, sets inProgress status and created a single payload of ids.
   * @returns {object} { enterpriseId: string, products: array }
   */
  groupPayloads = () => {
      const payload = {
          enterpriseId: this.enterpriseId,
          products: [],
          variants: [],
      };

      this.requests.forEach((obj) => {
          if (obj.inProgress) {
              return;
          }
          // eslint-disable-next-line no-param-reassign
          obj.inProgress = true;
          payload.products = payload.products.concat(obj.params.payload.products);

          if (obj.params.payload.variants?.length) {
              payload.variants = payload.variants.concat(obj.params.payload.variants);
          }
      });

      // unique variants only by variant type
      /*
         varaint structure
         {
            name: string,
            value: any,
         }
      */
      const variantNamesEncountered = {};
      payload.variants = payload.variants.filter((variant) => {
          if (variantNamesEncountered[variant.name]) {
              return false;
          }
          variantNamesEncountered[variant.name] = true;
          return true;
      });

      if (!payload.anonymousId) {
          payload.anonymousId = (typeof window !== 'undefined' && window.localStorage) ? useBrowserUUID() : '';
      }

      if (!payload.primaryBrand && typeof window !== 'undefined') {
          payload.primaryBrand = window.location.hostname?.split?.('.')?.[1] || '';
      }
      return payload;
  }

  /**
   * If the api call fails for some reason, clear the interval and finish all requests with an error
   * so the frontend and default to non price engine prices.
   * @param {string} message
   * @param {object} err
   */
  handleGroupedFetchFailure = (message, err) => {
      // bail out of this entirely and handle the failure in the component

      // clear the interval
      this.clearInterval(this.intervalId);

      const customError = new Error(`${message} ${err.message}`);

      // call finish on everything with the error
      this.requests.forEach((request, key) => {
          request.finish.forEach((func) => func(customError));
          this.requests.delete(key);
      });
  }

  /**
   * Triggers the grouping of payloads, api request, dispersing of data, and finishing.
   * @returns {undefined} no return
   */
  groupFetch = async () => {
      const payload = this.groupPayloads();

      if (!payload.products.length) {
          // for some reason, we have reuqests without partNumbers leading to an empty payload
          // in that event, we need to delete those useless request objects and end the interval
          this.cleanupBadRequests();
          return;
      }

      let res;

      try {
          res = await this.client.postFacade(this.env, this.resourcePage, this.jwtToken, payload);
          this.triggerImpressionEvents(res);
      } catch (err) {
          this.handleGroupedFetchFailure('Debouncer, network request failed:', err);
          return;
      }

      try {
          // sync up the requests with the resulting data
          this.disperseData(res.data);
      } catch (err) {
          this.handleGroupedFetchFailure('Debouncer, failed to disperse the data:', err);
          return;
      }

      try {
          // finish the requests
          this.finishRequests();
      } catch (err) {
          this.handleGroupedFetchFailure('Debouncer, failed to finish requests:', err);
      }
  }

  /**
   * Once the grouped request is finished, this function will coordinate the data
   * to the respective reuqest objects.
   * @param {object} data { products: array }
   */
  disperseData = (data) => {
      const listOfKeys = this.requests.keys();

      for (const key of listOfKeys) {
          // scenario of no matching price test
          // so well need a way to identify and finish those requests
          let keyHasMatch = false;
          //   eslint-disable-next-line no-loop-func
          data.products.forEach((product) => {
              if (!key.includes(product.partNumber)) {
                  return;
              }

              keyHasMatch = true;

              // found data for a request
              const request = this.requests.get(key);

              // do we already have the data, duplicates possible?
              if (request.nResponses >= request.nPartNumbers) {
                  return;
              }

              // give it the data
              request.data.products.push(product);
              // count the response
              request.nResponses += 1;

              // update map
              this.requests.set(key, request);
          });

          if (!keyHasMatch) {
              // if there is no match, signify this is done by making
              // rResponses equal nPartNumbers

              // this scenario occurs when there is no dynamic price
              // for a specific product
              const request = this.requests.get(key);
              request.nResponses = request.nPartNumbers;
              this.requests.set(key, request);
          }
      }
  }

  clearInterval = () => {
      clearInterval(this.intervalId);
      this.intervalId = null;
  }

  /**
   * A function the handle reuqests being added with no products.
   * This is triggered when a request somehow makes it into the map
   * with no products. Super unlikely but perform some cleanup just
   * in case.
   * @returns {undefined}
   */
  cleanupBadRequests = () => {
      this.requests.forEach((obj, key) => {
          // find requests with empty product arrays
          if (!obj.params.payload.products.length) {
              this.requests.delete(key);
          }
      });

      // after cleanup, we probably dont need the interval
      if (!this.requests.size) {
          this.clearInterval();
      }
  }

  /**
   * Find the requests that are completely fulfilled and wrap them up.
   * If a request only has a partial response, finish with an error
   * so it can default.
   * @returns {undefined}
   */
  finishRequests = () => {
      this.requests.forEach((val, key) => {
          if (val.nResponses === val.nPartNumbers) {
              // we are done with this

              this.requests.delete(key);
              val.finish.forEach((func) => func(null, val.data));
          }

          if (val.nResponses > 0 && val.nResponses < val.nPartNumbers) {
              // somehow a partial resposne for a group, let it default until
              // someone tells me its wrong
              this.requests.delete(key);
              val.finish.forEach((func) => func('Partial response recieved for request'));
          }
      });

      // clean up the interval if there is nothing in the map
      if (!this.requests.size) {
          this.clearInterval(this.intervalId);
      }
  }
}

export default Debouncer;
